/* ContactForm.module.css */
.formContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: -6rem;
}

.form {
  background: white;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 2.5rem;
  width: 100%;
  min-width: 500px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1b1e;
  margin-bottom: 2rem;
  text-align: center;
  text-shadow: none;
}

.inputRoot {
  margin-bottom: 1.5rem;
}

.input {
  background: #f8f9fa !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: #1a1b1e;
  transition: all 0.2s ease;
  height: 3.5rem;
}

.input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.input:focus {
  border-color: #0ea5e9;
  background: white !important;
}

.label {
  color: #1a1b1e;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.required {
  color: #ff6b6b;
}

.textarea {
  min-height: 8rem !important;
  padding: 0.75rem !important;
  line-height: 1.4 !important;
  background: #f8f9fa !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #1a1b1e !important;
  font-size: 1rem !important;
  transition: all 0.2s ease !important;
}

.textarea::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.textarea:focus {
  border-color: #0ea5e9 !important;
  background: white !important;
}

@media (max-width: 1024px) {
  .formContainer {
    justify-content: center;
    margin-right: 0;
  }

  .form {
    min-width: auto;
    max-width: 400px;
    padding: 2rem;
  }

  .title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
}

.label {
  color: #1a1b1e;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.required {
  color: var(--mantine-color-red-6);
  margin-left: 4px;
  font-size: 1.1rem;
}

.animatedButton {
  background: #0ea5e9;
  color: white;
  transition: all 0.2s;
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  font-size: 1rem;
  margin-top: 2rem;
  height: 3rem;
  font-weight: 500;
}

.animatedButton:hover {
  transform: scale(1.02);
  background: linear-gradient(45deg, var(--mantine-color-blue-5), var(--mantine-color-blue-8));
}
