/* MaintenanceHero.module.css */
.wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  color: #1a1b1e;
  padding: 0;
}

.mainContainer {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2rem 4rem;
  gap: 2rem;
  min-height: 90vh;
}

.content {
  text-align: left;
  margin-left: 0;
}

.title {
  font-size: 4.5rem !important;
  font-weight: 700;
  color: #1a1b1e;
  line-height: 1;
  margin-bottom: 1.5rem;
  text-shadow: none;
  letter-spacing: -0.03em;
}

.tagline {
  font-size: 2.5rem !important;
  opacity: 0.9;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.description {
  font-size: 1.5rem !important;
  color: #1a1b1e;
  opacity: 0.8;
  margin-top: 0;
  line-height: 1.6;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.ctaButton {
  display: none;
}

.formWrapper {
  width: 100%;
  max-width: 600px;
}

.ctaButton {
  background: #0ea5e9;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 2rem;
  box-shadow: 0 4px 12px rgba(14, 165, 233, 0.15);
}

.ctaButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(14, 165, 233, 0.25);
}

.ctaButton:active {
  transform: translateY(0);
}

@media (max-width: 1024px) {
  .inner {
    flex-direction: column;
    padding: 0;
    gap: 4rem;
  }

  .content {
    text-align: center;
    padding-right: 0;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
  }

  .formWrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    padding: 0 1rem 4rem 1rem;
  }

  .title {
    font-size: 3.5rem !important;
  }

  .tagline {
    font-size: 2rem !important;
  }

  .description {
    font-size: 1.25rem !important;
  }

  .ctaButton {
    display: block;
  }
}

.animatedButton {
  background: linear-gradient(45deg, var(--mantine-color-blue-4), var(--mantine-color-blue-7));
  color: white;
  transition: all 0.3s;
}

.animatedButton:hover {
  transform: scale(1.05);
}